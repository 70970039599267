import React from 'react'
import { Container } from 'react-bootstrap'
import AdminUsers from '../../components/admins/users/admin-users'

const AdminUsersPage = () => {
  return (
    <Container><AdminUsers/></Container>
  )
}

export default AdminUsersPage