import React from 'react'
import { Container } from 'react-bootstrap'
import AdminUserEdit from '../../components/admins/users/admin-user-edit'

const AdminUsersEditPage = () => {
  return (
    <Container><AdminUserEdit/></Container>
  )
}

export default AdminUsersEditPage