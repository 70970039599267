import React from 'react'
import { Container } from 'react-bootstrap'
import AdminUserNew from '../../components/admins/users/admin-user-new'

const AdminUsersNewPage = () => {
  return (
    <Container><AdminUserNew/></Container>
  )
}

export default AdminUsersNewPage