import React from "react";
import { Container } from "react-bootstrap";

const Map = () => {
  const mapEmbedUrl = process.env.REACT_APP_MAP_EMBED_URL;
  return (
    <Container>
      <iframe
        src={mapEmbedUrl}
        width="100%"
        height="450"
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </Container>
  );
};

export default Map;
