import React from "react";
import { Container } from "react-bootstrap";
import AdminVehicleEdit from "../../components/admins/vehicles/admin-vehicle-edit";

const AdminVehicleEditPage = () => {
  return (
    <>
      <Container><AdminVehicleEdit/></Container>
    </>
  );
};

export default AdminVehicleEditPage;
