import React from "react";
import { Container } from "react-bootstrap";
import AdminVehicleNew from "../../components/admins/vehicles/admin-vehicle-new";

const AdminVehiclesNewPage = () => {
  return (
    <>
      <Container><AdminVehicleNew/></Container>
    </>
  );
};

export default AdminVehiclesNewPage;
