import React from "react";
import { RiHeadphoneLine, RiMailOpenLine, RiMapPinLine } from "react-icons/ri";

const ContactBar = () => {

  const siteUrl = process.env.REACT_APP_SITE_URL;
  const phone1 = process.env.REACT_APP_PHONE1;
  const phone2 = process.env.REACT_APP_PHONE2;
  const address = process.env.REACT_APP_ADDRESS;



  return (
    <ul className="contact-bar">
      <li>
        <RiHeadphoneLine />
        <div>
          <a href={`tel:${phone1}`}>{phone1}</a>
          <br /><a href={`tel:${phone2}`}>{phone2}</a>
        </div>
      </li>
      <li className="d-none d-lg-flex">
        <RiMailOpenLine />
        <div>
          <a href={`mailto:info@${siteUrl}`}>info@{siteUrl}</a>
          <br /><a href={`mailto:support@${siteUrl}`}>support@{siteUrl}</a>
        </div>
      </li>
      <li className="d-none d-sm-flex">
        <RiMapPinLine />
        <div>
        {address}
        </div>
      </li>
    </ul>
  );
};

export default ContactBar;
